import * as Sentry from "@sentry/sveltekit";

Sentry.init({
    dsn: "https://9e0497f72470423a9306384e706d702a@o4505239743692800.ingest.sentry.io/4505313618952192",
    // Performance Monitoring:
    tracesSampleRate: 1.0, // Capture 100% of the transactions. Adjust this value in production as necessary.
    // Session Replay
    integrations: [new Sentry.Replay()],
    replaysSessionSampleRate: 0.1, // This sets the Replay sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});