import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/": [~5],
		"/cookies": [22],
		"/(app)/(signup-complete)/firebase": [6,[2,3]],
		"/(app)/(signup-complete)/inbox": [~7,[2,3]],
		"/privacy": [23],
		"/(app)/(signup-complete)/profile": [~8,[2,3]],
		"/(app)/signup/add-friend": [~14,[2,4]],
		"/(app)/signup/input-profile": [~15,[2,4]],
		"/(app)/signup/notification-permission-grant": [16,[2,4]],
		"/(app)/signup/phonenumber-verification": [17,[2,4]],
		"/(app)/signup/receiving-gift": [~18,[2,4]],
		"/(app)/signup/select-gender": [19,[2,4]],
		"/(app)/signup/select-school": [20,[2,4]],
		"/(app)/signup/verify-email-by-google": [~21,[2,4]],
		"/support": [24],
		"/(app)/(signup-complete)/survey": [9,[2,3]],
		"/terms": [25],
		"/(app)/(signup-complete)/vote": [~10,[2,3]],
		"/(app)/(signup-complete)/vote/completed": [~11,[2,3]],
		"/(app)/(signup-complete)/vote/requirement": [~12,[2,3]],
		"/(app)/(signup-complete)/vote/wait": [~13,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';